import { createSearchParams, generatePath, matchPath, PathMatch } from 'react-router-dom';
import { Article } from '../store/article/articleModels';
import { AccessModel, AuthModel } from '../store/auth/authModels';
import { ProductWarrantyType } from '../store/product/productModels';
import { SSOItem } from '../store/univers/universModels';
import { APP_URL, DEFAULT_COUNTRY, DEFAULT_COUNTRY_LANGUAGE_SEPARATOR, DEFAULT_LANGUAGE, LOCAL_STORAGE_KEYS, SLUG_ACCESS_KEYS, UNIVERS, WarrantyType } from './defines';
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem /*, setLocalStorageItemTtl*/ } from '../utils/helpers/localStorage';

export const isLocalhost = Boolean(window.location.hostname.includes('localhost'));

export const getAppUrl = (
  url: string,
  options?: {
    routeParams?: Record<string, string>;
    searchParams?: Record<string, string>;
  }
) => {
  let languagesData,
    languagesJson = getLocalStorageItem(LOCAL_STORAGE_KEYS.Language);

  if (languagesJson) {
    languagesData = JSON.parse(languagesJson);
  }

  let countryData,
    countryJson = getLocalStorageItem(LOCAL_STORAGE_KEYS.Country);

  if (countryJson && countryJson !== 'undefined') {
    countryData = JSON.parse(countryJson);
  }

  const { routeParams, searchParams } = options ?? {};

  let result = !routeParams
    ? url
    : generatePath(
        url,
        routeParams
          ? Object.fromEntries(
              Object.entries(routeParams).map((o) => [o[0], encodeURIComponent(o[1])])
            )
          : {}
      );

  if (searchParams) {
    const sp = createSearchParams(searchParams).toString();
    result = `${result}?${sp}`;
  }
  const pattern = matchPath({ path: APP_URL.Base, end: false }, window.location.pathname) as PathMatch;

  if (languagesData) {
    if (pattern) {
      const langFromPattern = languagesData?.languages?.some((l: any) => {
        return l.lang === pattern.params.lang;
      });

      if (langFromPattern) {
        return `${pattern?.pathname || ''}/${result}`;
      } else {
        return `/${countryData?.code || DEFAULT_COUNTRY}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${languagesData.currentLanguage || DEFAULT_LANGUAGE}/${result}`;
      }
    } else {
      return `/${countryData?.code || DEFAULT_COUNTRY}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${languagesData.currentLanguage || DEFAULT_LANGUAGE}/${result}`;
    }
  } else {
    return `/${DEFAULT_COUNTRY}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${DEFAULT_LANGUAGE}/${result}`;
  }
};

export const getUniversFromUrl = (domain?: string) => {
  let host = window.location?.host;
  //domain = UNIVERS.Other //UNIVERS.ThermorFR
  domain = UNIVERS.AtlanticFR; 

  Object.values(UNIVERS).some((univers) => {
    if (getUrlByUniverse(univers)?.includes(host)) {
      domain = univers;
      return true;
    }
    return null;
  });
  return domain;
};

export const getApiUrlBase = (univers: string) => {
  let pattern = process.env.REACT_APP_API_URL;
  let end_point = process.env.REACT_APP_API_END_POINT;
  return pattern! + end_point!;
};

export const getUrlByUniverse = (univers: string) => {
  let url: string | undefined = '';
  if (isLocalhost) {
    const parts = window.location?.host?.split('.');
    const index = parts.findIndex((p) => p.startsWith('localhost'));
    url = 'http://' + [univers, ...parts.slice(index)].join('.'); // NOSONAR
  } else {
    switch (univers) {
      case UNIVERS.Atlantic:
        url = process.env.REACT_APP_URL_Atlantic_INT;
        break;
      case UNIVERS.AtlanticFR:
        url = process.env.REACT_APP_URL_Atlantic_FR;
        break;
      case UNIVERS.Thermor:
        url = process.env.REACT_APP_URL_Thermor_INT;
        break;
      case UNIVERS.ThermorFR:
        url = process.env.REACT_APP_URL_Thermor_FR;
        break;
      case UNIVERS.Acv:
        url = process.env.REACT_APP_URL_ACV;
        break;
      case UNIVERS.AcvFR:
        url = process.env.REACT_APP_URL_ACV_FR;
        break;
      case UNIVERS.AutresMarques:
        url = process.env.REACT_APP_URL_Autres_marques;
        break;
      case UNIVERS.EquationWelcome:
        url = process.env.REACT_APP_URL_Equation_Welcome;
        break;
      case UNIVERS.Oem:
        url = process.env.REACT_APP_URL_OEM;
        break;
      case UNIVERS.Pacific:
        url = process.env.REACT_APP_URL_Pacific;
        break;
      case UNIVERS.Sauter:
        url = process.env.REACT_APP_URL_Sauter;
        break;
      case UNIVERS.Ygnis:
        url = process.env.REACT_APP_URL_Ygnis_INT;
        break;
    }
  }
  return url;
};

export const getApiUrl = (univers: string) => {
  return `${getApiUrlBase(univers)}/api`;
};

export const getUniversForUrl = (brand: string, forUniversRequest?: boolean) => {
  switch (brand) {
    case UNIVERS.AtlanticFR:
      return UNIVERS.Atlantic;
    case UNIVERS.AcvFR:
      return UNIVERS.Acv;
    case UNIVERS.ThermorFR:
      return forUniversRequest ? UNIVERS.EspaceSav : UNIVERS.Thermor;
  }
  return brand;
};

export const getBrandFolder = (brand: string) => {
  switch (brand) {
    case UNIVERS.AtlanticFR:
    case UNIVERS.ThermorFR:
      return getUniversForUrl(brand);
  }
  return 'other';
};

export const getSSOByUnivers = () => {
  let univers = getUniversFromUrl();
  switch (univers) {
    case UNIVERS.Atlantic:
    case UNIVERS.Acv:
    case UNIVERS.Thermor:
    case UNIVERS.Ygnis:
    case UNIVERS.AutresMarques:
    case UNIVERS.EquationWelcome:
    case UNIVERS.Oem:
        return "inter";
    case UNIVERS.AtlanticFR:
      return "atlantic";
    case UNIVERS.ThermorFR:
      return "thermor";
    case UNIVERS.AcvFR:
      return "acv";
    case UNIVERS.Sauter:
      return "sauter";
    case UNIVERS.Pacific:
      return "pacific";
  }

  return univers;
}

export const isGroupSSO = (listSSO: any) => {
  const lst_group = listSSO?.filter((u: { sso_code: string | string[] }) => {
    return u.sso_code && (u.sso_code.indexOf('group') !== -1 || u.sso_code.indexOf('inter') !== -1);
  });

  return lst_group && lst_group.length > 0;
};

export const getProSSO = (listSSO: any) => {
  return listSSO
    .filter((sso: any) => [UNIVERS.Atlantic, UNIVERS.Thermor].includes(sso.sso_code))
    .map((sso: any) => {
      return {
        ...sso,
        url: getSSOUrl(sso.sso_code),
      };
    });
};

export const getSSOListWithUrl = (listSSO: any) => {
  return listSSO?.map((sso: any) => {
    return {
      ...sso,
      url: getSSOUrl(sso.sso_code),
    };
  });
};

export function getSSOUrl(sso_code: string) {
  let univers = getUniversFromUrl();
  return getApiUrlBase(univers) + '/api/wso/redirect/' + univers.replace('-fr', '') + '/' + sso_code;
}

export const checkAccess = (user: AuthModel, accesSlug: string) => {
  let result = false;

  if (user?.acces) {
    result = user.acces.filter((acces: AccessModel) => acces.slug === accesSlug).length > 0;
  }
  return result;
};

export const isArticleAvailable = (article: Article) => {
  return article && article.articleReseau?.statut !== 4 && article.articleReseau?.statut !== 5;
};
export const getArticlePriceHT = (article: Article, listSSO: any, isLoggedIn: any) => {
  return isArticleAvailable(article) && (isLoggedIn || !isGroupSSO(listSSO)) && article?.articleReseau.prix_ht;
};
export const allowArticleAddToCard = (article: Article, listSSO: any, user: any) => {
  return (
    !article.ajoutPanier ||
    (article.articleReseau.prix_ht || 0) <= 0 ||
    article.article_uuid ||
    !article.articleReseau.isCommandable ||
    !isArticleAvailable(article)
  );
};

export const showArticleCreditCardIcon = (article: Article, user: AuthModel) => {
  let { articleReseau, paiementCb, isEnCompte } = article;
  const isLoggedIn = user?.active;
  const hasAccessCommande = isLoggedIn && checkAccess(user, SLUG_ACCESS_KEYS.Commande);
  return (
    isLoggedIn &&
    !isEnCompte &&
    hasAccessCommande &&
    paiementCb &&
    articleReseau?.disponibilite &&
    articleReseau?.isCommandable &&
    (articleReseau?.prix_ht as number) > 0
  );
};

export const disableArticleAddtoCartButton = (
  article: Article,
  user: AuthModel,
  listeSSO: SSOItem[],
  disabledLogin: boolean
) => {
  let { articleReseau, article_uuid, ajoutPanier } = article;
  const isLoggedIn = user?.active;
  const proSSOList = getProSSO(listeSSO);
  const canViewDiscountedPrice = checkAccess(user, SLUG_ACCESS_KEYS.SeeDiscountedPrice);

  return (
    (!isLoggedIn && (disabledLogin || !proSSOList?.length)) ||
    (!articleReseau) ||
    (articleReseau?.prix_ht as number) <= 0 ||
    !!article_uuid ||
    !articleReseau?.isCommandable ||
    !isArticleAvailable(article) ||
    (isLoggedIn && (!canViewDiscountedPrice || !ajoutPanier))
  );
};

export const getBreadcrumbQueryParams = (locationPath: string, id?: string, reseau_uuid?: string) => {
  if (matchPath({ path: APP_URL.Base + '/' + APP_URL.Family, end: true }, locationPath)) {
    return { famille_uuid: id };
  } else if (matchPath({ path: APP_URL.Base + '/' + APP_URL.ProductReseau, end: true }, locationPath)) {
    return { produit_uuid: id, reseau_uuid };
  } else if (
    matchPath({ path: APP_URL.Base + '/' + APP_URL.Product, end: true }, locationPath) ||
    matchPath({ path: APP_URL.Base + '/' + APP_URL.ProductSchema, end: true }, locationPath) ||
    matchPath({ path: APP_URL.Base + '/' + APP_URL.ProductReseau, end: true }, locationPath)
  ) {
    return { produit_uuid: id };
  } else if (matchPath({ path: APP_URL.Base + '/' + APP_URL.Article, end: true }, locationPath)) {
    return { article_uuid: id };
  } else if (matchPath({ path: APP_URL.Base + '/' + APP_URL.ArticleReseau, end: true }, locationPath)) {
    return { article_uuid: id, reseau_uuid };
  }
  return null;
};

export const renderPrice = (price: number) => {
  if (price > 0) return formatNumberEuro(price);
  else return 'article.prix.consult';
};
export const formatNumberEuro = (price: number) => {
  price = price ?? 0;
  return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price);
};

export const getDocGARight = (tags: string) => {
  const droits = ['PFS_PUBLIC', 'PFS_PRO', 'PFS_SATC', 'PFS_SAVA', 'PFS_CMISTE'];

  let documentTags = tags.split(','),
    finalTags: any[] = [];

  documentTags.forEach((tag) => {
    if (droits.includes(tag)) finalTags.push(tag);
  });

  return finalTags;
};

/**
 * Returns client type by user and reseau uuid
 * @param  {[AuthModel]} user
 * @param  {[string]} reseau_uuid
 * @return {[Boolen]}
 */
export function getClientType(user: AuthModel, reseau_uuid: string) {
  if (!user.societe || !user.societe.societe_reseau) return null;
  let societe_reseau = user.societe.societe_reseau.find((sr) => sr.reseau_uuid === reseau_uuid);
  if (!societe_reseau) return null;
  return societe_reseau.station_sav ? 'station-sav' : 'client-en-compte';
}

/**
 * Retourne si le type de garantie est compatatible avec le type de demande
 * @param  { [ProductWarrantyType]} typeGarantie
 * @param  { [string]} warrantyRequstType
 * @return { [Boolean]}
 */

export function isTypeGarantiesAllowed(typeGarantie: ProductWarrantyType, warrantyRequstType: string) {
  let typeAllowed: string[] = [];
  if (warrantyRequstType === WarrantyType.Commande) {
    typeAllowed = ['fiche-dincident', 'fiche-dincident-mod', 'avg', 'avg-mod'];
  } else if (warrantyRequstType === WarrantyType.Retour) {
    typeAllowed = ['fiche-dincident', 'fiche-dincident-mod'];
  }
  return typeGarantie && typeAllowed.indexOf(typeGarantie.slug) > -1;
}

export function isWarrantyRequestAllowedForUniverse(univers: string) {
  return univers !== UNIVERS.Sauter;
}

export function getWarrantyPrintUrl(warranty_request_uuid: string, univers_uuid: string, lang: string) {
  const redirectUrl = `${getApiUrl(
    getUniversFromUrl()
  )}/garanties/demande/${warranty_request_uuid}/impression?${univers_uuid}&lang=${lang}`;

  return `${APP_URL.RedirectResource}?url=${escape(redirectUrl)}`;
}



export const loadResourceFromServer = (url: string, callback: any) =>{
  let token = getLocalStorageItem(LOCAL_STORAGE_KEYS.UserToken);
  if (!token){
    token = getLocalStorageItem(LOCAL_STORAGE_KEYS.AppToken);
  }
  if (token){
    let headers = {
      'Authorization': "Bearer " + token,
    };
    const fetchOptions = { 
      method: "GET",
    };
    let params = {
      ...fetchOptions, 
        headers: headers
    };
    fetch(url, params)
      .then((response)=>response.arrayBuffer())
      .then((buffer: ArrayBuffer)=>{
        const blob = new Blob([new Uint8Array(buffer, 0, buffer.byteLength)]);    
        const reader = new FileReader();
        reader.onload = (event: any) => {
          const dataUrl = event.target.result;
          callback(dataUrl);
          // do something with base64
        };
          
        reader.readAsDataURL(blob);
        
    });
    }
}

export const redirectToUrl = (url: string) =>{
  getAppToken(()=>{
    let app_token = getLocalStorageItem(LOCAL_STORAGE_KEYS.AppToken);
    if (app_token){
      let headers = {
        'Authorization': "Bearer " + app_token,
      };
      const fetchOptions = { 
        method: "GET",
      };
      let params = {
        ...fetchOptions, 
          headers: headers
      };
      fetch(url, params)
        .then((response)=>response.json())
        .catch((response)=>{
          if (response.error?.originalStatus === 401){
            window.location.reload();
          }
        })
        .then((response)=>{
          //debugger
          window.location.href =  response.redirect_url;
      });
    } else {
      window.location.href = url;
    }
  })
}

export const redirectToUrlForUser = (url: string) =>{
    getUserToken(()=>{
      let user_token = getLocalStorageItem(LOCAL_STORAGE_KEYS.UserToken);
      if (user_token){
        let fd = "user_token=" + user_token;
        let headers = {
          'Authorization': "Bearer " + user_token,
          "Content-Type": "application/x-www-form-urlencoded"
        };
        if (isLocalhost) {
          headers = {
            "x-Authorization": "Bearer " + user_token,
            "Content-Type": "application/x-www-form-urlencoded"
          } as any;
        }
        const fetchOptions = { 
          method: "POST",
          body: fd
        };
        let params = {
          ...fetchOptions, 
            headers: headers
        };
        fetch(url, params)
          .then((response)=>response.json())
          .catch((response)=>{
            if (response.error?.originalStatus === 401){
              window.location.reload();
            }
          })
          .then((response)=>{
            //debugger
            if (response.redirect_url) {
              window.location.href =  response.redirect_url;
            }
        });
      } else {
        window.location.href = url;
      }
  })
}

export const loginWithCode = (url: string, onSuccess: any, onError: any) =>{
  let app_token = getLocalStorageItem(LOCAL_STORAGE_KEYS.AppToken);
  if (app_token){
    let headers = {
      'Authorization': "Bearer " + app_token,
    };
    const fetchOptions = { 
      method: "GET",
    };
    let params = {
      ...fetchOptions, 
        headers: headers
    };
    fetch(url, params)
      .then((response)=>response.json())
      .then((response)=>{
        //debugger
        if (response && response.token?.access_token){
          setLocalStorageItem(LOCAL_STORAGE_KEYS.UserToken, response.token.access_token);
          setLocalStorageItem(LOCAL_STORAGE_KEYS.RefreshToken, response.token.refresh_token);
          setLocalStorageItem(LOCAL_STORAGE_KEYS.SessionCode, response.session_code);
          setLocalStorageItem(LOCAL_STORAGE_KEYS.LogoutUrl, response.wso_url_logout);
          onSuccess && onSuccess(response);
        } else {
          onError && onError(response);
        }
        
    });
  }
}

export const loginConnectAs = (url: string, callback: any, onError: any) =>{

  getAppToken(()=>{
    let app_token = getLocalStorageItem(LOCAL_STORAGE_KEYS.AppToken);
    if (app_token){
      let headers = {
        'Authorization': "Bearer " + app_token,
      };
      const fetchOptions = { 
        method: "GET",
      };
      let params = {
        ...fetchOptions, 
          headers: headers
      };
      
      try {
        fetch(url, params)
          .then((response)=>response.json())
          .catch((response)=>{
            onError && onError(response);
          })
          .then((response)=>{
            //debugger
            if (response && response.token){
              setLocalStorageItem(LOCAL_STORAGE_KEYS.UserToken, response.token);
              setLocalStorageItem(LOCAL_STORAGE_KEYS.RefreshToken, "");
              setLocalStorageItem(LOCAL_STORAGE_KEYS.SessionCode, response.session_code);
              callback && callback(response);
            }
        });
      }
      catch(error)
      {
        onError && onError();
      }
    }
  })
  
}

export const getLogoutCallbackUrl = (url: string, callback: any, onError: any) =>{

  const session_code = getLocalStorageItem(LOCAL_STORAGE_KEYS.SessionCode);
  if (session_code){
    url += `&session_code=${session_code}`;
  }
  let app_token = getLocalStorageItem(LOCAL_STORAGE_KEYS.UserToken);
  if (app_token){
    let headers = {
      'Authorization': "Bearer " + app_token,
    };
    const fetchOptions = { 
      method: "GET",
    };
    let params = {
      ...fetchOptions, 
        headers: headers
    };
    
    try {
      fetch(url, params)
        .then((response)=>response.json())
        .catch((response)=>{
          onError && onError(response);
        })
        .then((response)=>{
          //debugger
          console.log(response);
          if (response && response.back_url){
            removeLocalStorageItem(LOCAL_STORAGE_KEYS.SessionCode);
            removeLocalStorageItem(LOCAL_STORAGE_KEYS.RefreshToken);
            removeLocalStorageItem(LOCAL_STORAGE_KEYS.AppToken);
            callback && callback(response.back_url);
          }
      });
    }
    catch(error)
    {
      onError && onError();
    }
  }
  
}


export const downloadFromUrl = (url: string, callback: any) =>{
  let token = getLocalStorageItem(LOCAL_STORAGE_KEYS.UserToken);
  if (!token){
    token = getLocalStorageItem(LOCAL_STORAGE_KEYS.AppToken);

  }
  if (token){
    let session_code = getLocalStorageItem(LOCAL_STORAGE_KEYS.SessionCode);
    if (session_code && url.indexOf("session_code") === -1){
      if (url.indexOf("?") === -1) {
        url += `?session_code=${session_code}`;
      } else {
        url += `&session_code=${session_code}`;
      }
    }

    let headers = {
      'Authorization': "Bearer " + token,
    };
    const fetchOptions = { 
      method: "GET",
    };
    let params = {
      ...fetchOptions, 
        headers: headers
    };
    fetch(url, params)
      .then((response)=>response.blob())
      .then((blob: Blob)=>{
        const imageUrl = URL.createObjectURL(blob);
        callback(imageUrl);
      });
    }
}

const app_back_api_url = process.env.REACT_APP_API_TOKEN_URL;
export const getAppToken = (callback: any) =>{
  let fd = "grant_type=client_credentials&univers=nosso";
  let headers = { "Content-Type": "application/x-www-form-urlencoded" };
  const fetchOptions = { 
    method: "POST",
    body: fd
  };
  let params = {
    ...fetchOptions, 
      headers: headers
  };

  fetch(`${app_back_api_url ?? ''}/api/token`, params as any)
        .then((response) => {
            return response.json()
        })
        .then((result) => {
          if (result && result.access_token){
            setLocalStorageItem(LOCAL_STORAGE_KEYS.AppToken, result.access_token);
            callback && callback(result);
          }
        })
        .catch((error) =>{
          console.log(error);
        });

    return null;
}

export const getUserToken = (callback: any) =>{
  let user_token = getLocalStorageItem(LOCAL_STORAGE_KEYS.UserToken);
  if (user_token){
    let univers = getSSOByUnivers();
    let refresh_token = getLocalStorageItem(LOCAL_STORAGE_KEYS.RefreshToken);
    let fd = "grant_type=refresh_token";
    fd += "&univers="+ univers.replace('-fr', '');
    fd += "&refresh_token=" + refresh_token;
    fd += `&redirect_uri=${window.location.origin}/wso/callback`;
  
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const fetchOptions = { 
      method: "POST",
      body: fd
    };
    let params = {
      ...fetchOptions, 
        headers: headers
    };

    fetch(`${app_back_api_url ?? ''}/api/token`, params as any)
        .then((response) => {
          return response.json()
        })
        .then((result) => {
          if (result && result.access_token){
            setLocalStorageItem(LOCAL_STORAGE_KEYS.UserToken, result.access_token);
            setLocalStorageItem(LOCAL_STORAGE_KEYS.RefreshToken, result.refresh_token);
            callback && callback(result);
          }
        })
        .catch((error) =>{
          console.log(error);
        });

    return null;
  }
}